import React, { useContext, useEffect, useRef, useState } from "react";
import { SettingsContext } from "@/context/SettingsContext";
import { HeaderContext } from "@/components/Header/header";
import { useOnClickOutside } from "../../../hooks/useOnClickOutside";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
const GlobalNavOverlay = dynamic(import("./global-header-overlay"));
import styles from "./global-header.module.scss";
import classnames from "classnames";
import AnimatedMenuIcon from "./animated-menu-icon";

interface Props {
  duration: number;
  className?: string;
}

const GlobalHeader = ({ duration, className }: Props) => {
  const { settings } = useContext(SettingsContext);
  const ref = useRef(null);
  const stories = settings?.content?.global_nav || [];
  const { setGlobalHeaderOpen } = useContext(HeaderContext);
  const [active, setActive] = useState(false);
  useEffect(() => setGlobalHeaderOpen(active), [active, setGlobalHeaderOpen]);
  useOnClickOutside(ref, () => setActive(false));
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = () => setActive(false);
    router.events.on("routeChangeStart", handleRouteChange);
    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  });

  return stories.length > 0 ? (
    <div ref={ref} className={className}>
      <GlobalNavOverlay active={active} duration={duration} stories={stories} />
      <div
        className={classnames(
          styles.globalHeader,
          "layout-grid",
          active ? styles.active : ""
        )}
      >
        <AnimatedMenuIcon
          active={active}
          clickHandler={() => setActive(!active)}
          duration={duration}
        />
        <div
          className={classnames(
            styles.globalHeader__item,
            styles.globalHeader__title
          )}
        >
          <h1 className={styles.globalHeader__titleContent}>Ef Pro Cycling</h1>
        </div>
        <div
          className={classnames(
            styles.globalHeader__item,
            styles.globalHeader__link
          )}
        >
          <a
            className={classnames(
              styles.globalHeader__item,
              styles.globalHeader__linkContent
            )}
            href="https://ef.com"
          >
            ef.com
          </a>
        </div>
      </div>
    </div>
  ) : null;
};

export default GlobalHeader;
