import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import classnames from "classnames";

import styles from "./animated-menu-icon.module.scss";

interface Props {
  active: boolean;
  clickHandler: () => void;
  duration: number;
  className?: string;
}

const AnimatedMenuIcon = ({
  active,
  clickHandler,
  duration,
  className,
}: Props) => {
  const tlRef = useRef(gsap.timeline({ paused: true }));
  const ref = useRef<HTMLButtonElement>(null);
  const q = gsap.utils.selector(ref);
  duration = duration / 3;

  useEffect(() => {
    gsap.set(q(".line1, .line2"), {
      transformOrigin: "50%",
    });
    tlRef.current
      .to(q(".menu-toggle"), {
        rotation: 90,
        duration,
        ease: "power2.out",
      })
      .to(q(".line1"), {
        y: 5.5,
        duration,
        ease: "power2.out",
      })
      .to(
        q(".line2"),
        {
          y: -5.5,
          duration,
          ease: "power2.out",
        },
        "<"
      )
      .to(q(".line1"), {
        rotation: 45,
        duration,
        ease: "power2.out",
      })
      .to(
        q(".line2"),
        {
          rotation: -45,
          duration,
          ease: "power2.out",
        },
        "<"
      );
  }, [tlRef, duration]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let tl: gsap.core.Timeline | null = null;
    if (tlRef.current) {
      tl = tlRef.current;
      active
        ? tlRef.current.timeScale(1).restart()
        : tl.timeScale(1.5).reverse();
    }
    return () => {
      tl && tl.kill();
    };
  }, [active]);

  return (
    <button
      className={classnames(styles.menuIcon, className)}
      ref={ref}
      onClick={clickHandler}
      aria-label="Other EF Pro Cycling sites menu"
    >
      <svg
        className="menu-toggle"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 32"
      >
        <title>Menu Icon</title>
        <g className="line1">
          <rect className="rect1" y="9" width="32" height="3" rx="1" ry="1" />
        </g>
        <g className="line2">
          <rect className="rect2" y="20" width="32" height="3" rx="1" ry="1" />
        </g>
      </svg>
    </button>
  );
};

export default AnimatedMenuIcon;
